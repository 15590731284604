import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import SEO from './components/SEO';
import { AuthProvider, useAuth } from './AuthContext';
import Navigation from './components/Navigation';
import SharedBookPage from './components/SharedBookPage';
import LogRocket from 'logrocket';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import LoadingSpinner from './components/LoadingSpinner';
import Profile from './components/Profile';
import PaymentComplete from './components/PaymentComplete';
import UpgradePlan from './components/UpgradePlan';
import Contact from './components/Contact';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import NotFound from './components/404';
import BlogPage from './components/blog';
import BlogPost1 from './components/BlogPost1';
import BlogPost2 from './components/BlogPost2';
import BlogPost3 from './components/BlogPost3';

LogRocket.init('l6jusz/kidz-book');

const LandingPage = React.lazy(() => import('./components/LandingPage'));
const PhotoUploadPage = React.lazy(() => import('./components/PhotoUploadPage'));
const PDFBookGenerator = React.lazy(() => import('./components/PDFBookGenerator'));
const HowItWorksPage = React.lazy(() => import('./components/HowItWorksPage'));
const GalleryPage = React.lazy(() => import('./components/GalleryPage'));
const ChildThemeBook = React.lazy(() => import('./components/ChildThemeBook'));
const TermsAndConditions = React.lazy(() => import('./components/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const Register = React.lazy(() => import('./components/Register'));
const Login = React.lazy(() => import('./components/Login'));

function AppContent() {
  const { logout } = useAuth();

  useEffect(() => {
    const handleUserLoggedOut = () => {
      logout();
      // Optionally, you can redirect to the login page or show a notification
    };

    window.addEventListener('user-logged-out', handleUserLoggedOut);

    return () => {
      window.removeEventListener('user-logged-out', handleUserLoggedOut);
    };
  }, [logout]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  return <AppRoutes isLoading={isLoading} setIsLoading={setIsLoading} />;
}

function AppRoutes({ isLoading, setIsLoading }) {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 300);
    return () => clearTimeout(timer);
  }, [location, setIsLoading]);

  return (
    <div className="app-container flex flex-col min-h-screen">
      <Navigation />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Suspense fallback={<LoadingSpinner />}>
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/how-it-works" element={<HowItWorksPage />} />
              <Route path="/upload" element={<PhotoUploadPage />} />
              <Route path="/generate-pdf" element={<PDFBookGenerator />} />
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/book/:bookId" element={<PDFBookGenerator />} />
              <Route path="/shared-book/:bookId" element={<SharedBookPage />} />
              <Route path="/child-theme-book" element={<ChildThemeBook />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/shared-theme-book/:bookId" element={<SharedBookPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={isLoggedIn ? <Profile /> : <Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/reset-password/*" element={<ResetPassword />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/payment-complete" element={<PaymentComplete />} />
              <Route path="/upgrade-plan" element={<UpgradePlan />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blogpost1" element={<BlogPost1 />} />
              <Route path="/blogpost2" element={<BlogPost2 />} />
              <Route path="/blogpost3" element={<BlogPost3 />} />
              <Route path="/Creating-Holiday-Book-Gift" element={<BlogPost3 />} />
              <Route path="/Create-Personalized-Children's-Books" element={<BlogPost1 />} />
              <Route path="/Our-Journey-with-AI-Powered-Storytelling" element={<BlogPost2 />} />
            </Routes>
          </div>
        </Suspense>
      )}
      <footer className="bg-white py-6 text-center">
        <div className="container mx-auto">
          <div className="mb-4">
            <a href="/terms-and-conditions" className="text-gray-600 hover:text-pink-500 transition duration-300 mx-2">Terms and Conditions</a>
            <a href="/privacy-policy" className="text-gray-600 hover:text-pink-500 transition duration-300 mx-2">Privacy Policy</a>
            <a href="/contact" className="text-gray-600 hover:text-pink-500 transition duration-300 mx-2">Contact Us</a>
            <a href="/blog" className="text-gray-600 hover:text-pink-500 transition duration-300 mx-2">Blog</a>
          </div>
          <div className="text-sm text-gray-500 mb-3">
            Join our magical book adventure on social media! 📚✨
          </div>
          <div className="flex justify-center space-x-4">
            <a href="https://www.linkedin.com/company/kidzbook/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 transition duration-300">
              <FaLinkedin size={24} />
            </a>
            <a href="https://x.com/Kidz_Book" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600 transition duration-300">
              <FaTwitter size={24} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <SEO 
          title="KidzBook - Free AI-Powered Children's Books"
          description="Create and read free online children's books powered by AI. Personalized stories for kids using cutting-edge technology."
          keywords="AI for children, AI for kids, free online children's books, personalized stories"
        />
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
